import { graphql, withPrefix, Link } from 'gatsby';

import * as React from 'react';
import { A } from '../components/a';
import Footer from '../components/footer';
import Navbar from '../components/navbar';
import { Location, SiteMetadata } from '../models';
import EmailPopup from '../components/email-popup';
import ContactForm from '../components/contact-form';
import Seo from '../components/seo'

interface Props {
    data: {
      site: SiteMetadata;
    };
    location: Location;
  };


const elearning = ({ data, location }: Props) => {
    return (
    <div id="main_content">
        <Seo title='Service'/>
        <Navbar dark={true} />
        <EmailPopup/>
  
  
        <section className="page-banner">
          <div className="container">
            <div className="page-title-wrapper">
              <h1 className="page-title">Service</h1>
  
              <ul className="bradcurmed">
                <li>
                  <Link to="/">
                      Home
                  </Link>              
                </li>
                <li>Service</li>
              </ul>
            </div>
          </div>
  
          <svg
            className="circle"
            data-parallax='{"x" : -200}'
            xmlns="http://www.w3.org/2000/svg"
            width="950px"
            height="950px"
          >
            <path
              fillRule="evenodd"
              stroke="rgb(250, 112, 112)"
              strokeWidth="100px"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              opacity="0.051"
              fill="none"
              d="M450.000,50.000 C670.914,50.000 850.000,229.086 850.000,450.000 C850.000,670.914 670.914,850.000 450.000,850.000 C229.086,850.000 50.000,670.914 50.000,450.000 C50.000,229.086 229.086,50.000 450.000,50.000 Z"
            />
          </svg>
  
          <ul className="animate-ball">
            <li className="ball"></li>
            <li className="ball"></li>
            <li className="ball"></li>
            <li className="ball"></li>
            <li className="ball"></li>
          </ul>
        </section>

        <section className="editor-design">
        <div className="container">
          <div className="row">
            <div className="editure-feature-image wow pixFadeRight">
              <div className="image-one" data-parallax='{"x" : 30}'>
                <img
                  src="images/screenshots/screenshot-10-min.png"
                  className="wow pixFadeRight rounded shadow"
                  data-wow-delay="0.3s"
                  alt="feature-image"
                />
              </div>
              <div className="image-two">
                <div className="image-two-inner image-two-vertical" data-parallax='{"x" : -30}'>
                  <img
                    src="images/screenshots/screenshot-1-min.png"
                    className="wow pixFadeLeft rounded shadow"
                    data-wow-delay="0.5s"
                    alt="feature-image"
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 offset-lg-6">
              <div className="editor-content">
                <div className="section-title style-two">
                  <h2 className="title wow pixFadeUp" data-wow-delay="0.3s" style={{ fontWeight: '500', fontSize: '70px'}}>
                    We develop it all!
                  </h2>
                </div>

                <div className="description wow pixFadeUp" data-wow-delay="0.5s">
                  <p>
                    If you want to leave the entire course to professionals, we 
                    can help. All you have to do is send us the details of your 
                    course material in a document (word, ppt) and <b>we will 
                    create your complete e-learning course based on it.</b>
                    <br /><br />
                    Ask us for quote.
                  </p>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div className="shape-bg">
          <img src="media/background/shape_bg.png" className="wow fadeInLeft" alt="shape-bg" />
        </div>
      </section>

      <section>
        <div className='bg-angle-what'>
          
          <div className="container" style={{ 
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              // marginTop: '20px',
              marginBottom: '20px'}}>
              <h3 style={{ color: 'white', fontWeight: '500', fontSize: '50px'}}>Contact us!</h3>
              <br />
              <ContactForm usedClassName='col-md-8' usedButtonClassName='btn-white' usedConsentTextClassName='consent-text-white'/>
          </div>
        </div> 
      </section>
      <br />

      <Footer />
    </div>
    )
};

export default elearning;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;